var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('div',{staticClass:"login-intro d-flex align-center justify-center fill-height"},[_c('v-card',{staticClass:"login-card",attrs:{"outlined":"","elevation":"3","max-width":_vm.$vuetify.breakpoint.smAndUp ? '505' : ''}},[_c('v-card-title',{staticClass:"\n          py-4\n          px-6\n          justify-center\n          text-h3\n          foodie\n          font-weight-bold\n          white--text\n        "},[_vm._v(" Welcome! ")]),(_vm.active_staff.length || _vm.is_loading)?_c('v-card-text',{staticClass:"pa-6"},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('p',{staticClass:"text-h4 py-4"},[_vm._v("Please choose your profile to log in.")]),_c('div',{staticClass:"staff-list"},_vm._l((_vm.active_staff),function(member){return _c('div',{key:member.id,staticClass:"d-flex flex-column align-center cursor-pointer",on:{"click":function($event){return _vm.select_staff(member)}}},[_c('Avatar',{staticClass:"mb-2",attrs:{"variant":"beam","colors":[
                  '#FBEAC7',
                  '#54C4C9',
                  '#7ACB9F',
                  '#A582C7',
                  '#FF8080' ],"name":member.name}}),_c('p',{staticClass:"text-h5 mb-2 text-center"},[_vm._v(_vm._s(member.name))])],1)}),0)])]):_c('v-card-text',[_c('div',{staticClass:"ma-4 text-center"},[_c('h3',{staticClass:"py-2"},[_vm._v("There are no staff members yet")]),_c('p',{staticClass:"py-3 mr-2"},[_vm._v(" Your shop admin must create some before you log in ")]),_c('v-btn',{attrs:{"rounded":"","color":"foodie","to":"/logout"}},[_c('span',{staticClass:"white--text"},[_vm._v("Go to Login")])])],1)]),(_vm.active_staff.length)?_c('v-divider'):_vm._e(),(_vm.active_staff.length)?_c('v-card-actions',[_c('router-link',{staticClass:"d-flex align-center mb-1 mx-2",attrs:{"color":"foodie","to":"/logout"}},[_c('v-icon',{attrs:{"small":"","color":"foodie","left":""}},[_vm._v("mdi-arrow-left-bold")]),_c('span',{staticClass:"mt-1 ml-1"},[_vm._v("Log out")])],1)],1):_vm._e()],1),(_vm.selected_staff)?_c('Keypad',{attrs:{"error_message":_vm.keypad_error_message,"loading":_vm.is_validating},on:{"close":function($event){_vm.select_staff(null);
        _vm.error_message = '';
        _vm.error_message_timeout = null;}},model:{value:(_vm.pin),callback:function ($$v) {_vm.pin=$$v},expression:"pin"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }