<template>
  <v-main>
    <div class="login-intro d-flex align-center justify-center fill-height">
      <v-card
        outlined
        elevation="3"
        class="login-card"
        :max-width="$vuetify.breakpoint.smAndUp ? '505' : ''"
      >
        <v-card-title
          class="
            py-4
            px-6
            justify-center
            text-h3
            foodie
            font-weight-bold
            white--text
          "
        >
          Welcome!
        </v-card-title>
        <v-card-text class="pa-6" v-if="active_staff.length || is_loading">
          <div class="d-flex flex-column align-center">
            <p class="text-h4 py-4">Please choose your profile to log in.</p>

            <div class="staff-list">
              <div
                v-for="member in active_staff"
                :key="member.id"
                class="d-flex flex-column align-center cursor-pointer"
                @click="select_staff(member)"
              >
                <Avatar
                  class="mb-2"
                  variant="beam"
                  :colors="[
                    '#FBEAC7',
                    '#54C4C9',
                    '#7ACB9F',
                    '#A582C7',
                    '#FF8080',
                  ]"
                  :name="member.name"
                />
                <p class="text-h5 mb-2 text-center">{{ member.name }}</p>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <div class="ma-4 text-center">
            <h3 class="py-2">There are no staff members yet</h3>
            <p class="py-3 mr-2">
              Your shop admin must create some before you log in
            </p>
            <v-btn rounded color="foodie" to="/logout">
              <span class="white--text">Go to Login</span>
            </v-btn>
          </div>
        </v-card-text>
        <v-divider v-if="active_staff.length" />
        <v-card-actions v-if="active_staff.length">
          <router-link
            color="foodie"
            class="d-flex align-center mb-1 mx-2"
            to="/logout"
          >
            <v-icon small color="foodie" left>mdi-arrow-left-bold</v-icon>
            <span class="mt-1 ml-1">Log out</span>
          </router-link>
        </v-card-actions>
      </v-card>
      <Keypad
        v-if="selected_staff"
        v-model="pin"
        :error_message="keypad_error_message"
        :loading="is_validating"
        @close="
          select_staff(null);
          error_message = '';
          error_message_timeout = null;
        "
      />
    </div>
  </v-main>
</template>

<script>
import moment from "moment";
import Avatar from "vue2-boring-avatars";

import { mapActions, mapGetters, mapState } from "vuex";
import Keypad from "@/components/Keypad";

export default {
  name: "StaffLogin",
  components: {
    Keypad,
    Avatar,
  },
  data() {
    return {
      pin: "",
      min_pin_length: 4,
      login_timeout_id: null,
      keypad_error_message: "",
      error_message_timeout: null,
    };
  },
  methods: {
    ...mapActions("StaffStore", [
      "get_staff_members",
      "select_staff",
      "validate_staff_member",
      "clear_logged_in_staff",
    ]),
    format_last_seen(datetime_string) {
      return "Last seen " + moment(datetime_string).format("DD/MM/YYYY  hh:mm");
    },
    async submit_login() {
      if (this.is_validating) return;
      try {
        const payload = {
          staff: this.selected_staff,
          pin: this.pin,
        };
        await this.validate_staff_member(payload);
        this.pin = "";
      } catch (e) {
        console.error("Failed while logging in staff member", e);
        const error_message = e?.response?.data?.detail?.message;
        if (!error_message) return;
        this.keypad_error_message = error_message;
        if (this.error_message_timeout)
          clearTimeout(this.error_message_timeout);
        this.error_message_timeout = setTimeout(() => {
          this.keypad_error_message = "";
        }, 3250);
        this.pin = "";
      }
    },
  },
  computed: {
    ...mapState("StaffStore", [
      "is_loading",
      "is_validating",
      "selected_staff",
      "logged_in_staff",
    ]),
    ...mapGetters("StaffStore", ["active_staff"]),
  },
  watch: {
    selected_staff() {
      this.pin = "";
      if (this.error_message_timeout) clearTimeout(this.error_message_timeout);
      this.keypad_error_message = "";
    },
    logged_in_staff(staff) {
      if (staff) {
        this.$router.push("/");
        this.select_staff(null);
      }
    },
    pin(pin) {
      if (pin.length < this.min_pin_length) return;
      this.submit_login();
    },
  },
  async mounted() {
    if (this.logged_in_staff) await this.clear_logged_in_staff();
    try {
      this.get_staff_members();
    } catch (e) {
      console.error("Failed to fetch staff members ", e);
    }
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.circular-wrapper {
  position: relative;
}
.loading-circular {
  position: absolute;
  top: 10px;
}
.login-card {
  border-radius: 10px;
}
.login-intro {
  @media #{ map-get($display-breakpoints, 'md-and-up')} {
    background-position: center;
    background-image: url("~@/assets/images/wearelocals_bg_1.svg");
    background-size: cover;
  }
}

.login-intro-mobile {
  height: 100%;
  width: 100%;

  @media #{ map-get($display-breakpoints, 'sm-and-down')} {
    background-image: url("~@/assets/images/wearelocals_bg_2.svg");
    background-position: center bottom;
    background-size: 160%;
  }
}

.staff-list {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px 20px;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: start;
}
</style>
